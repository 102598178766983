import { render, staticRenderFns } from "./templateTitle.vue?vue&type=template&id=7ba0db1c&scoped=true"
import script from "./templateTitle.vue?vue&type=script&lang=js"
export * from "./templateTitle.vue?vue&type=script&lang=js"
import style0 from "./templateTitle.vue?vue&type=style&index=0&id=7ba0db1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ba0db1c",
  null
  
)

export default component.exports