<!-- 模块标题 -->

<template>
  <div class="flex-between title-box">
    <!-- <div class="icon"></div> -->
    <div class="title">{{ this.title }}</div>
    <div class="border">{{this.rightcon}}</div>
  </div>
</template>

<script>
// 导入组件
// import headerComponent from "./head.vue";
// 导出模块
export default {
  // 模块名字
  name: 'templateTitle',
  props: ['title',"rightcon"],
  // 模块数据
  data() {
    //数据
    return {}
  },
  // 注册组件
  components: {
    // headerComponent
  },
  // 监听指定值，只有指定值变化，才会触发
  watch: {},
  // 里面的函数只有调用才会执行（实时计算）里面是定义的方法
  methods: {}
}
</script>
<style lang="scss" scoped>
.title-box {
  align-items: center;
  height: 0.25rem;
  margin-bottom: 0.125rem;
  .icon {
    width: 0.04rem;
    height: 0.19rem;
    background: #00a0e9;
    border-radius: 0.04rem;
    margin: 0 0.1rem;
  }

  .title {
    // width: 1.875rem;
    // height: 0.375rem;
    font-size: 0.175rem;
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    color: #212A33;
  }

  .border {
    // width: 1.875rem;
    // height: 0.375rem;
    // background-color: red;
    color: #818C98;
    font-size: 14px;
    cursor: pointer;

    &:hover{
      color: #00a0e9;
    }
  }
}
</style>
